@import 'node_modules/devextreme/dist/css/dx.common.css';
@import 'node_modules/devextreme/dist/css/dx.light.css';
@import 'node_modules/devexpress-gantt/dist/dx-gantt.min.css';

@import 'bootstrap-scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';

.companyLogo {
  padding-right: 120px !important;
  width: 55px;
  height: 55px;
  background-image: url("../images/garpa.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.header-title {
  font-size: 26px;
  padding-left: 20px;
}


